<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="alignnone size-full wp-image-23639" src="@/assets/news/24/1.png" width="777" height="724" alt="图片"  sizes="(max-width: 777px) 100vw, 777px"></p>
        <p>随着塞浦路斯护照暂停，葡萄牙黄金签证的变政，欧洲各国的移民政策在不停的快速变化中。作为这两年移民界的宠儿土耳其护照项目也传来了涨价消息。</p>
        <p>据报道，近日由总统雷杰普·塔伊普·埃尔多安（Recep Tayyip Erdogan）领导的总统内阁讨论了提高必须购买的房地产价值以获得特殊土耳其公民身份的问题。</p>
        <p>将增至40万美元</p>
        <p>在内阁会议上，拟定将申请特殊土耳其公民身份的房地产价值从25万美元增加到40万美元。在这项决定的范围内，拟修改《土耳其公民法执行条例》第20条的“b”节，其中载有获得土耳其公民身份的规定。</p>
        <p><img loading="lazy" class="alignnone size-full wp-image-23640" src="@/assets/news/24/2.jpg" width="828" height="1629" alt="图片"  sizes="(max-width: 828px) 100vw, 828px"></p>
        <p>土耳其房价一路“高涨”</p>
        <p>在世界非常有名的房产咨询公司Knight Frank也发布了《2021年（Q4）全球房产价格指数》，根据其中数据显示，全球56个国家及地区平均涨幅为10.3%，而土耳其以59.6%的涨幅继续领跑全球房价位列榜首！</p>
        <p>同时，根据REIDIN数据统计，土耳其的房价2022年1月环比上涨了20.88%，比十年前上涨121.20%。</p>
        <p>这也就更加吸引各国投资者的关注，更重要的是，土耳其身份可以帮助他们完成身份规划、解决子女教育、资产配置、全球出行等多重需求。而现行的土耳其相关法律规定，购买25万美元的房产，即可授予土耳其居民身份和护照，且房产3年后即可出售。这不仅增强了土耳其买房移民的吸引力，也更加积极推动了国际投资者投资土耳其房产。也因涌入大量投资者入籍，将刺激土耳其当局的“涨价动力”，也再一次证明了且“移”，且珍惜。相信后疫情时代，土耳其会带来新一波的投资热潮。</p>
        <p><strong><img loading="lazy" class="alignnone size-full wp-image-23641" src="@/assets/news/24/3.png" width="996" height="617" alt="图片"  sizes="(max-width: 996px) 100vw, 996px"></strong></p>
        <p>土耳其</p>
        <p>投资入籍项目</p>
        <p>项目优势</p>
        <p>高性价比<br>
          仅需投资25万美元起，获永久产权土耳其房产，可合法出租</p>
        <p>赴美跳板<br>
          持土耳其护照，可申请美国E-2签证，1年内登陆美国</p>
        <p>子女教育<br>
          外籍身份有助于孩子以国际生身份就读国内的部分国际学校，及免试入读清华、北大等国内名校*<br>
          *详细规则以学校实时招生政策为准</p>
        <p>出行便利<br>
          持土耳其护照，即可免签112个国家和地区，包括日本、中国香港、新加坡等</p>
        <p>入籍方便快捷<br>
          6-8个月政府审批，无移民监，无语言要求，无管理经验要求及面签要求</p>
        <p>申请要求简单<br>
          无需申报个人资金来源和收入</p>
        <p><img loading="lazy" class="alignnone size-full wp-image-23642" src="@/assets/news/24/4.jpg" width="1080" height="624" alt="图片"  sizes="(max-width: 1080px) 100vw, 1080px"></p>
        <p>土耳其护照申请条件<br>
          1.年满18周岁</p>
        <p>2.无犯罪记录</p>
        <p>3.完成要求的房产投资</p>
        <p>4.申请人的合法配偶、18周岁以下子女均可随同申请</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news24",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:24,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>